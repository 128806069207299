import { defineStore } from 'pinia';
import { useGtm } from '@gtm-support/vue-gtm';

export const useBookingOverlayStore = defineStore('booking-overlay', {
    state: () => {
        return {
            bookingOverlayOpen: false,
            bookingOverlaySelectedLocation: null
        };
    },

    getters: {
        isBookingOverlayOpen() {
            return this.bookingOverlayOpen;
        }
    },

    actions: {
        toggleBookingOverlay() {
            this.bookingOverlayOpen = !this.bookingOverlayOpen;
        },

        openBookingOverlay({ location = null, parentComponent = null }) {
            const gtm = useGtm();
            gtm.trackEvent({
                /* eslint-disable camelcase */
                event: 'book_your_stay_click',
                parent_component: parentComponent
                /* eslint-enable camelcase */
            });

            if (location && typeof location === 'object') {
                this.bookingOverlaySelectedLocation = location;
            }
            this.bookingOverlayOpen = true;
        },

        closeBookingOverlay() {
            this.bookingOverlayOpen = false;
        }
    }
});
